export const version = "1.0.11.d";

//#region ---------- AWS ----------
export let domain = "https://boapi.devh5apidemo.xyz/api/v2/backend";
let topupReceiptUploadDomain = "https://boapi.devh5apidemo.xyz/api/topup/receipt-md5";
let uploadDomain = "https://boapi.devh5apidemo.xyz/api/topup/receipt2";
let fileDomain    = "https://boapi.devh5apidemo.xyz/public/receipt/";
let uploadsFolder =  "https://boapi.devh5apidemo.xyz/uploads";
let botDomain    = "https://www.ogbot.site/api/v2/rest";
let updateCssVariablesDomain = "https://boapi.devh5apidemo.xyz/api/updateCssVariable";
let saveOrOpdateCssDraftDomain = "https://boapi.2f826cab.xyz/api/saveCssDraft";

//#endregion

//#region ---------- AWS Staging----------
// export let domain = "https://api.abc9339.xyz/api/v2/backend";
// // export let domain = "https://www.2b6ec8df.com/api/v2/rest";
// let uploadDomain = "https://www.2b6ec8df.com/api/topup/receipt2";
// let fileDomain = "https://www.2b6ec8df.com/public/receipt/";
// //#endregion

//#region ---------- TERA ----------
// export let domain = "https://www.78f6e121.com/api/v2/rest";
// let uploadDomain = "https://www.79ede6a0.com/api/topup/receipt2";
// let topupReceiptUploadDomain = "https://www.79ede6a0.com/api/topup/receipt-md5";
// let fileDomain = "https://www.79ede6a0.com/public/receipt/";
// let uploadsFolder =  "https://78f6e121.com/uploads/";
// let botDomain    = "https://www.ogbot.site/api/v2/rest";

// export let domain = "https://777bo_api.ogstaging5.xyz/api/v2/backend";
// let uploadDomain = "https://777bo_api.ogstaging5.xyz/api/topup/receipt2";
// let topupReceiptUploadDomain = "https://777bo_api.ogstaging5.xyz/api/topup/receipt-md5";
// let fileDomain = "https://777bo_api.ogstaging5.xyz/public/receipt/";
// let uploadsFolder = "https://777bo_api.ogstaging5.xyz/uploads/";
// let botDomain = "https://www.ogbot.site/api/v2/rest";
// let updateCssVariablesDomain = "https://777bo_api.ogstaging5.xyz/api/updateCssVariable";
// let saveOrOpdateCssDraftDomain = "https://777bo_api.ogstaging5.xyz/api/saveCssDraft";

// export let domain = "https://www.5a283031.com/api/v2/rest";
// let uploadDomain = "https://www.5a283031.com/api/topup/receipt2";
// let topupReceiptUploadDomain = "https://www.5a283031.com/api/topup/receipt-md5";
// let fileDomain = "https://www.5a283031.com/public/receipt/";
// let uploadsFolder =  "https://5a283031.com/uploads/";
// let botDomain    = "https://www.ogbot.site/api/v2/rest";
//#endregion

export {
  uploadDomain,
  fileDomain,
  botDomain,
  uploadsFolder,
  topupReceiptUploadDomain,
  updateCssVariablesDomain,
  saveOrOpdateCssDraftDomain,
};
