export const settings = {
    "version":"1.2.6.6.1Z",
    "appName": "Whitelabel Template",
    "companyName" : "OgCloud Tech",
    "decimalScale": 4,
    "decimalScaleMobile":2 ,
    "processTypeManual" :"M",
    "processTypeApp"    :"A",
    "pageSizeOptions"   : ["10", "50", "100", "500", "1000"],
    "pageSizeOptionsSmall"   : ["10", "20","50", "100"],
    "pageSizeOptionsMedium"   : ["100", "250", "500"],
    "pageSizeOptionsLarge"   : ["100", "200", "500","1000"],
    "monitorRefreshTime" :"300000", //5 minutes in ms

};